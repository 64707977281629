import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import DashboardService from '@/services/dashboard';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { useTranslation } from '@/hooks/translations';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useLoyaltyContext } from '@/contexts/loyalty';
import { covertUtcToRestaurantDateTime } from '@/common/utility';
import { Section } from '@/components/common';
import { FilterProps } from '@/views/Dashboard';
import { LoyaltyChartData, LoyaltyRevenueChartData } from '@/interfaces/dashboard/types';
import SkeletonLoadingContent from '../SkeletonLoadingContent';

export default function LoyaltyChart({ timeRange }: FilterProps) {
    const dashboardService = DashboardService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const { lang } = router.query as { lang?: string };
    const [chartData, setChartData] = useState<LoyaltyChartData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const { restaurant } = useRestaurantContext();
    const { selectedProgram } = useLoyaltyContext();
    const [chartMode, setChartMode] = useState<'redemption' | 'earn'>('redemption');

    const { from, to } = timeRange;
    const period = timeRange.type;

    const handleTabChange = (_e: React.SyntheticEvent, newValue: 'redemption' | 'earn') => {
        setChartMode(newValue);
    };

    const id = restaurant?.id;
    const programId = selectedProgram?.id;

    useEffect(() => {
        if (id && programId) {
            setLoading(true);
            dashboardService
                .getLoyaltyChartReport({
                    programId,
                    period,
                    from,
                    to,
                    id,
                    isLegacy: true,
                })
                .then((res) => {
                    const resChartData = res.data;
                    setChartData(resChartData);
                })
                .catch(() => {
                    setError(t('Something went wrong'));
                })
                .finally(() => setLoading(false));
        }
    }, [id, timeRange, programId]);

    const skeletonRevenueChart = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" sx={{ height: '300px', borderRadius: '8px' }} />
            </Grid>
        </Grid>
    );

    return (
        <Grid item xs={12}>
            <Section sx={{ borderColor: '#E5E5E5', borderWidth: '1px', borderRadius: '20px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {
                            sm: 'row',
                            xs: 'column',
                        },
                        justifyContent: 'space-between',
                    }}
                >
                    <Tabs value={chartMode} onChange={handleTabChange} variant="fullWidth">
                        <Tab value="redemption" label={t('Redemption')} />
                        <Tab value="earn" label={t('Earn')} />
                    </Tabs>
                </Box>
                <SkeletonLoadingContent loading={loading} error={error} skeleton={skeletonRevenueChart()}>
                    <ResponsiveContainer width="99%" height={300}>
                        {chartData ? (
                            <AreaChart
                                data={chartData.revenueChart.map((item: LoyaltyRevenueChartData) => ({
                                    date: covertUtcToRestaurantDateTime(
                                        item.date,
                                        lang === 'ja' ? 'MMM DD[日]' : 'DD MMM',
                                    ),
                                    revenue:
                                        chartMode === 'earn'
                                            ? parseFloat(item.earnPoints).toFixed(
                                                  getCurrencyPrecision(chartData.currencyCode),
                                              )
                                            : parseFloat(item.burnPoints).toFixed(
                                                  getCurrencyPrecision(chartData.currencyCode),
                                              ),
                                }))}
                                margin={{
                                    top: 40,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="00%" stopColor="#7D00D4" />
                                        <stop offset="25%" stopColor="#7D00D4" />
                                        <stop offset="100%" stopColor="#C4C4C4" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis
                                    dataKey="date"
                                    tick={{
                                        fontSize: '14px',
                                    }}
                                />
                                <YAxis
                                    dataKey={(v) => parseInt(v.revenue, 10)}
                                    tick={{
                                        fontSize: '14px',
                                    }}
                                    axisLine={false}
                                />
                                <Tooltip
                                    formatter={(value: any) => [
                                        parseFloat(value).toFixed(getCurrencyPrecision(chartData.currencyCode)),
                                        '',
                                    ]}
                                    separator=""
                                    cursor={{ stroke: '#000000', strokeWidth: 1, strokeDasharray: '10 5' }}
                                    contentStyle={{
                                        backgroundColor: '#FAFAFABF',
                                        borderRadius: '10px',
                                        borderWidth: '0px',
                                        minWidth: '120px',
                                        backdropFilter: 'blur(5px)',
                                        color: '#000000',
                                    }}
                                    itemStyle={{ color: '#000000' }}
                                />
                                <CartesianGrid stroke="#E5E5E5" strokeDasharray="0" vertical={false} />
                                <Area
                                    type="monotone"
                                    dataKey="revenue"
                                    stroke="#7D00D4"
                                    strokeWidth={2}
                                    fillOpacity={1}
                                    fill="url(#colorRev)"
                                    activeDot={{
                                        stroke: '#0075FF',
                                        fill: '#0075FF',
                                    }}
                                />
                            </AreaChart>
                        ) : (
                            <span />
                        )}
                    </ResponsiveContainer>
                </SkeletonLoadingContent>
            </Section>
        </Grid>
    );
}
