import { useState, SyntheticEvent, ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { useRestaurantContext } from '@/contexts/restaurant';
import { useUserContext } from '@/contexts/user';
import { useLoyaltyContext } from '@/contexts/loyalty';
import { restrictRoles } from '@/constants/roleRestrictions';
import { ReportType } from '@/common/utility';

import { TimeRangeTypes, type ITimeRangeForm } from '@/components/TimeRange/Form/types';
import RevenueChart from '@/components/Dashboard/RevenueChart';
import BestsellingItems from '@/components/Dashboard/BestsellingItems';
import NewInfoCards from '@/components/Dashboard/InfoCards';
import Filters from '@/components/Dashboard/Filters';
import LoyaltyFilters from '@/components/Dashboard/Loyalty/Filters';
import LoyaltyInfoCards from '@/components/Dashboard/Loyalty/InfoCards';
import LoyaltyChart from '@/components/Dashboard/Loyalty/Chart';
import { Box, Tab, Tabs } from '@mui/material';

export interface FilterProps {
    timeRange: ITimeRangeForm;
}

export enum LegacyDashboardTypes {
    ORDER = 'order',
    TRANSACTIONS = 'transactions',
    REFRESH = 'refresh',
}

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`reports-tabpanel-${index}`}
            aria-labelledby={`reports-tab-${index}`}
        >
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `reports-tab-${index}`,
        'aria-controls': `reports-tabpanel-${index}`,
    };
}

export default function Dashboard() {
    const [value, setValue] = useState(0);

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="reports tab">
                    <Tab label="Revenue Reports" {...a11yProps(0)} />
                    <LoyaltyTab />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Revenue />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Loyalty />
            </TabPanel>
        </>
    );
}

function LoyaltyTab(props: any) {
    const { selectedProgram } = useLoyaltyContext();
    if (!selectedProgram?.id) return null;

    return <Tab label="Loyalty Reports" {...a11yProps(1)} {...props} />;
}

function Revenue() {
    const { restaurant } = useRestaurantContext();
    const { user } = useUserContext();

    const todayDate = new Date();
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: TimeRangeTypes.TODAY,
        from: formatISO(startOfDay(todayDate)),
        to: formatISO(endOfDay(todayDate)),
    });

    const defaultDashboardIsTransaction = restaurant?.config?.defaultDashboardIsTransaction || false;

    const [legacyDashboard, setLegacyDashboard] = useState<LegacyDashboardTypes>(
        defaultDashboardIsTransaction ? LegacyDashboardTypes.TRANSACTIONS : LegacyDashboardTypes.ORDER,
    );
    const [refreshBoolean, setRefreshBoolean] = useState<boolean>(false);

    const reportType = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role)
        ? ReportType.BRAND
        : ReportType.RESTAURANT;

    return (
        <Grid container spacing={2}>
            <Filters
                setTimeRange={setTimeRange}
                legacyDashboard={legacyDashboard}
                setLegacyDashboard={setLegacyDashboard}
                setRefreshBoolean={setRefreshBoolean}
            />

            <NewInfoCards
                timeRange={timeRange}
                isLegacy={legacyDashboard === LegacyDashboardTypes.TRANSACTIONS}
                refreshBoolean={refreshBoolean}
            />
            <RevenueChart timeRange={timeRange} type={reportType} />
            {restaurant?.config?.vendorShowBestsellingItems && <BestsellingItems />}
        </Grid>
    );
}

function Loyalty() {
    const todayDate = new Date();
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: TimeRangeTypes.TODAY,
        from: formatISO(startOfDay(todayDate)),
        to: formatISO(endOfDay(todayDate)),
    });

    return (
        <Grid container spacing={2}>
            <LoyaltyFilters setTimeRange={setTimeRange} />
            <LoyaltyInfoCards timeRange={timeRange} />
            <LoyaltyChart timeRange={timeRange} />
        </Grid>
    );
}
